import React from 'react';
import './style.css';

function Footer () {
  return (
    <div className='footer-wrapper'>
      <div className='footer-container'>
        <div className='footer-left'>
          <div>I&G Trucking</div>
          <a href="tel:1111111111">
            <span className='material-icons'>call</span>
            111-111-1111
          </a>
          <a href='mailto:someEmail@gmail.com'>
            <span class="material-icons">mail</span>
            emailAddress@gmail.com
          </a>
        </div>
        <div className='footer-right'>
          MONTEREY COUNTY, CA
        </div>
      </div>
    </div>
  );
}

export default Footer;