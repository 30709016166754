import React from 'react';
import './style.css';
import LeftIconText from '../leftIconText/LeftIconText.js';

function Contact () {
  return (
    <div className='contact-wrapper'>
      <div className='contact-container'>
        <div className='contact-left'>
          <h2>Contact us</h2>
        </div>
        <div className='contact-right'>
          <a href='tel:1111111111'>
            <LeftIconText iconId='call' text='111-111-1111' color='white' />
          </a>
          <a href='mailto:someEmail@gmail.com'>
            <LeftIconText iconId='mail' text='emailAddress@gmail.com' color='white' />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;