import React from 'react';
import './style.css';
import temp from '../../assets/images/tempPhoto.png';
import { SECTION_IDS } from '../../services/constants.js';
import LeftIconText from '../leftIconText/LeftIconText.js';

function OurServices () {
  const handlePrimaryClick = (e) => {
    e.preventDefault();
    console.log('primary clicked');
  };

  return (
    <div className='our-services-wrapper' id={SECTION_IDS.services}>
      <div className='our-services-container'>
        <div className='our-services-image-mobile'>
          <img src={temp} alt='our-services'/>
        </div>
        <div className='our-services-left'>
          <p className='our-services-pretext'>OUR SERVICES</p>
          <h1>We ship locally</h1>
          <p className='our-services-subtext'>Dedicated account manager and our round-the-color support team will always have your back.</p>
          <div className='our-services-icon-tags'>
            <LeftIconText iconId='location_on' text='LOCATION' />
            <LeftIconText iconId='location_on' text='LOCATION' />
            <LeftIconText iconId='location_on' text='LOCATION' />
            <LeftIconText iconId='location_on' text='LOCATION' />
            <LeftIconText iconId='location_on' text='LOCATION' />
          </div>
          <div className='our-services-buttons'>
            <button className='button-primary' onClick={(e) => handlePrimaryClick(e)}>PRIMARY</button>
          </div>
        </div>
        <div className='our-services-right'>
          <img src={temp} alt='our-services'/>
        </div>
      </div>
    </div>
  );
}

export default OurServices;