import React from 'react';
import './style.css';

/**
 * @param {iconId, text, color} props
 */

function LeftIconText (props) {
  const getColor = () => {
    return props.color ? props.color : 'var(--secondary-dark-color)';
  };

  return (
    <span className='left-icon-container'>
      <span
        className = 'material-icons'
        style     = {{color: getColor()}}
      >
        {props.iconId}
      </span>
      <span>{props.text}</span>
    </span>
  );
}

export default LeftIconText;