import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey           : 'AIzaSyDZlZsUYETjwqv8a-o_mesMSl_dL7dhpLg',
  authDomain       : 'ing-trucking.firebaseapp.com',
  projectId        : 'ing-trucking',
  storageBucket    : 'ing-trucking.appspot.com',
  messagingSenderId: '1093125578834',
  appId            : '1:1093125578834:web:5b80a6c7b79ddca775bf8b',
  measurementId    : 'G-PZZXWHVK9D'
};

// Initialize Firebase
initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
