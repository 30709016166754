import React from 'react';
import './style.css';
import { SECTION_IDS } from '../../services/constants.js';
import temp from '../../assets/images/tempPhoto.png'
import LeftIconText from '../leftIconText/LeftIconText.js';

function Home () {
  const handlePrimaryClick = (e) => {
    e.preventDefault();
    console.log('primary clicked');
  };

  const handleSecondaryClick = (e) => {
    e.preventDefault();
    console.log('secondary clicked');
  };

  return (
    <div className='home-wrapper'>
      <div className='home-container' id={SECTION_IDS.home}>
        <div className='home-image-mobile'>
          <img src={temp} alt='Home'/>
        </div>
        <div className='home-left'>
          <h1>Local, reliable shipping services</h1>
          <div className='home-icon-tags'>
            <LeftIconText iconId='location_on' text='LOCAL' />
            <LeftIconText iconId='attach_money' text='FAIR PRICING' />
            <LeftIconText iconId='favorite' text='FAMILY-OWNED' />
          </div>
          <div className='home-buttons'>
            <button className='button-primary' onClick={(e) => handlePrimaryClick(e)}>PRIMARY</button>
            <button className='button-secondary' onClick={(e) => handleSecondaryClick(e)}>SECONDARY</button>
          </div>
          <div className='home-hiring-link'>
            <a href={SECTION_IDS.jobs}>WE'RE HIRING! &gt;</a>
          </div>
        </div>
        <div className='home-right'>
          <img src={temp} alt='Home'/>
        </div>
      </div>
    </div>
  );
}

export default Home;