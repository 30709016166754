import './App.css';
import Header from './components/header/Header.js';
import Home from './components/home/Home.js';
import OurServices from './components/ourServices/OurServices.js';
import Contact from './components/contact/Contact.js';
import Jobs from './components/jobs/Jobs.js';
import Footer from './components/footer/Footer.js';

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <OurServices />
      <Contact />
      <Jobs />
      <Footer />
    </div>
  );
}

export default App;
