import React from 'react';
import './style.css';
import temp from '../../assets/images/tempPhoto.png'
import { SECTION_IDS } from '../../services/constants.js';
import LeftIconText from '../leftIconText/LeftIconText.js';

function Jobs () {
  const handlePrimaryClick = (e) => {
    e.preventDefault();
    console.log('primary clicked');
  };

  return (
    <div className='jobs-wrapper' id={SECTION_IDS.jobs}>
      <div className='jobs-container'>
        <div className='jobs-image-mobile'>
          <img src={temp} alt='jobs'/>
        </div>
        <div className='jobs-left'>
          <p className='jobs-pretext'>JOIN OUR TEAM</p>
          <h1>We're hiring!</h1>
          <p className='jobs-subtext'>Join our team for great pay and services as either a full-time or part-time driver</p>
          <div className='jobs-icon-tags'>
            <LeftIconText iconId='location_on' text='LOCATION' />
            <LeftIconText iconId='attach_money' text='PRICING' />
            <LeftIconText iconId='favorite' text='SERVICE' />
            <LeftIconText iconId='location_on' text='LOCATION' />
            <LeftIconText iconId='attach_money' text='PRICING' />
            <LeftIconText iconId='favorite' text='SERVICE' />
          </div>
          <div className='jobs-buttons'>
            <button className='button-primary' onClick={(e) => handlePrimaryClick(e)}>PRIMARY</button>
          </div>
        </div>
        <div className='jobs-right'>
          <img src={temp} alt='jobs'/>
        </div>
      </div>
    </div>
  );
}

export default Jobs;